@import "~bootstrap/dist/css/bootstrap.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 3.7s linear;
  }
}

.App-header {
  background: linear-gradient(#44444d, #0f0f11);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-title {
  font-size: 48px;
}

.NavBar {
  background: linear-gradient(#33333f, #2f2f3a);
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
}

.Navbar-height {
  height: 7.6vh;
}

.NavBar-logo {
  width: 30%;
  height: 30%;
  fill: white;
}

.NavBar-title {
  font-size: 36px;
  text-align: left;
  color: #FFFFFF;
  padding-left: 5%;
}

.NavBar-items {
  text-align: right;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  0%, 100% {
    transform: translateY(4px);
  }
  40% {
    transform: translateY(-4px);
  }
}

.Footer {
  background-image: linear-gradient(rgb(248, 249, 250), rgb(215, 218, 217));
}

.Footer-small {
  height: 5vh;
}

.dark-curve {
  background: linear-gradient(#1d1d21, #121213);
}

.Footer-dark {
  background: linear-gradient(#1d1d21, #121213);
}

.dark-text {
  color: #6c757d;
  filter: brightness(125%);
}

.darker-text {
  color: #6c757d;
  filter: brightness(65%);
}

.bg-darker {
  background-color: #1a1c1f;
}

.input-dark {
  background-color: #1a1c1f !important;
  border-color: #48484f !important;
  box-shadow: 0 0 0 0 !important;
}

.rounded-corners {
  border-radius: 50px;
  padding: 20px;
}

.full-height {
  height: 87.4vh; /* Navbar is 7.6vh. Calculate with: 100 - footer-small.height - navbar.height (7.6) */
}

.message-box {
  height: 80vh;
}

.rotate-curve {
  transform: rotateX(180deg);
}